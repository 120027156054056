<template>
  <v-container class="ml-5 mr-5 my-5">
    <v-layout row wrap>
        <v-form name="register-form" ref="registerUserForm" autocomplete='off' v-model="isFormVisiblyValid" lazy-validation>
          <v-stepper v-model="stepperStep" vertical flat>

            <!-- mobile -->
            <v-stepper-step
              :complete="stepperStep > 1"
              editable
              step="1">
              主要联系方式
              <small>手机号码将成联系您的主要方式</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card class="mb-5" flat>
                <v-text-field
                  label="手机号码"
                  name="mobile"
                  v-model="mobile"
                  :rules ="mobileRules"
                ></v-text-field>
              </v-card>
              <v-btn color="primary"
                @click="stepperStep = 2"
                :disabled="!isFormVisiblyValid">
                下一步
              </v-btn>
              <v-btn text @click="cancel">取消</v-btn>
            </v-stepper-content>

            <!-- password -->
            <v-stepper-step
              :complete="stepperStep > 2"
              editable
              step="2">
              请输入您的密码
              <small>密码将采用Hash技术存储</small>
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card class="mb-5" flat>
                <v-text-field
                  label="密码"
                  type="password"
                  name="password"
                  hint="至少6位，仅可以使用数字及大小写字母"
                  :rules ="passwordRules"
                  v-model="password"
                ></v-text-field>
                <v-text-field
                  label="确认密码"
                  type="password"
                  name="passwordConfirm"
                  :rules ="passwordConfirmRules"
                  v-model="passwordConfirm"
                ></v-text-field>
              </v-card>
              <v-btn color="primary" :disabled="!isFormVisiblyValid" @click="stepperStep = 3">
                下一步
              </v-btn>
              <v-btn text @click="cancel">取消</v-btn>
            </v-stepper-content>

            <!-- role -->
            <v-stepper-step
              :complete="stepperStep > 3"
              editable
              step="3">
              请选择帐户类型
              <small>根据身份选择</small>
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card class="mb-5" flat>
                <v-radio-group v-model="router" :rules="[v => !!v || '请选择身份']">
                  <v-radio
                    :key="1"
                    :label='`教师`'
                    :value='`register-tutor`'
                  ></v-radio>
                  <v-radio
                    :key="2"
                    :label='`学员`'
                    :value='`register-student`'
                  ></v-radio>
                  <v-radio
                    :key="3"
                    :label='`承接方`'
                    :value='`register-host`'
                  ></v-radio>
                </v-radio-group>
              </v-card>
              <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || '须阅读并同意才能继续']"
                label="我已阅读并同意协议"
                required
              ></v-checkbox>
              <br>
              <div class="error" v-html="error" />
              <br>
              <v-btn color="primary" :disabled="!isFormVisiblyValid" @click="submit({ mobile: mobile, password: password })">
                <v-progress-circular
                  v-if="showWait"
                  indeterminate
                  color="white"
                  :size="20"
                  :width="2"
                ></v-progress-circular>
                <span v-if="showWait">&nbsp;</span>
                保存，并填写相关信息
              </v-btn>
              <v-btn text @click="cancel">取消</v-btn>
            </v-stepper-content>

          </v-stepper>
        </v-form>
    </v-layout>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  components: {
  },
  computed: {
  },
  data () {
    return {
      isFormVisiblyValid: true,
      router: '',
      mobile: '',
      mobileRules: [
        v => !!v || '手机为必填项',
        v => /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/.test(v) || '请输入正确的手机号码'
      ],
      password: '',
      passwordRules: [
        v => /^[a-zA-Z0-9]{6,16}$/.test(v) || '密码只能包含数字及大小写字母，并为6-16位'
      ],
      passwordConfirm: '',
      passwordConfirmRules: [
        v => v === this.password || '密码两次输入不一致'
      ],
      checkbox: false,
      error: null,
      stepperStep: 1,
      userId: null,
      showWait: false
    }
  },
  methods: {
    async submit (credentials) {
      if (this.$refs.registerUserForm.validate()) { // the form element is marked as ref="form" and can be refered here
        this.showWait = true
        const res = await this.registerUser(credentials)
        console.log('response received, res:', res)
        if (res.userId) {
          console.log('user created! userId: ', res.userId)
          this.userId = res.userId
          this.showWait = false
          this.error = null
          this.$router.push({
            name: this.router,
            params: {
              userId: this.userId }
          })
        } else {
          this.error = res.err
          console.log('err occured: ', res.err)
          this.showWait = false
        }
      }
    },
    cancel () {
      this.$refs.registerUserForm.reset()
      this.error = null
      this.stepperStep = 1
    },
    async registerUser (credentials) {
      // credentials = {mobile: '', password: ''}
      try {
        const response = await AuthenticationService.registerUser({
          mobile: credentials.mobile,
          password: credentials.password
        })
        console.log('registerUser was called!', credentials.mobile, credentials.password, 'response.data:', response.data)
        return response.data
      } catch (err) {
        return err.response.data
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
